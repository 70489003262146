import CurrencyContent from "./CurrencyContent"

const FooterBottom=()=>{
    return(
        <div className="footer_bottom">
        <CurrencyContent/>
        <p>&copy; ModaMia 2023. All Rights Reserved.</p>
        </div>

    )
}

export default FooterBottom;
/* eslint-disable react/jsx-pascal-case */
import { Route, Routes, useLocation } from "react-router-dom";
import contents from "routes/contentRoutes";
import { ArrayType } from "shared/helpers/helpers";
import { Header } from "./Header";
import Footer from "./Footer";
import Aside from "layout/Aside/Aside";
import NotFound from "pages/NotFound";
import { useSelector } from "react-redux";
import { isLogged } from "redux/reducers/userReducer";
import PrivateWrapper from "layout/PrivateWrapper/PrivateWrapper";
import { Fragment, useEffect, useState } from "react";

const AppRouter = () => {
  const logged = useSelector(isLogged);
  const location = useLocation();
  const restrict_aside = !location.pathname.includes("customization/");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const restrict_header =
    ((location.pathname.includes("customization/") ||
      location.pathname.includes("design-a-dress/")) &&
      ((screenWidth <= 1050 && height >= 1000) || screenWidth <= 910)) ||
    location.pathname.includes("get-measured");

  const restrict_footer =
    ((location.pathname.includes("customization/") ||
      location.pathname.includes("design-a-dress/")) &&
      ((screenWidth <= 1050 && height >= 1000) || screenWidth <= 910)) ||
    location.pathname.includes("get-measured");

  const { publicPages, privatePages } = contents;

  const allPages = [...publicPages, ...privatePages];

  const renderRoutes = (pages: ArrayType) => {
    return pages.map((RouteElem) => (
      <Fragment key={RouteElem.id}>
        <Route
          path={RouteElem.path}
          element={
            RouteElem?.isPrivate ? (
              <PrivateWrapper>
                <RouteElem.element />
              </PrivateWrapper>
            ) : (
              <RouteElem.element />
            )
          }
        />
        {RouteElem.children && renderRoutes(RouteElem.children)}
      </Fragment>
    ));
  };

  return (
    <>
      {!restrict_header && <Header />}
      {logged && restrict_aside && <Aside />}
      <main className="main-content">
        <Routes>
          {allPages && renderRoutes(allPages)}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      {!restrict_footer && <Footer />}
    </>
  );
};

export default AppRouter;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blur_block {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 30%;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}

.not_found_container {
  position: relative;
  background-color: #E4C0AB;
  height: 100vh;
  width: 100%;
}

.go_to_home {
  text-align: center;
  font: 16px;
  font-weight: bold;
  letter-spacing: 0;
  padding: 0px 20px;
  margin: 5px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  min-height: 40px;
  line-height: 1;
  border-radius: 5px;
  color: #aa8a75;
  border: 1px solid #aa8a75;
  background-color: #fff;
  text-decoration: unset;
}
.go_to_home:hover {
  color: #fff;
  border: 1px solid #aa8a75;
  background-color: #aa8a75;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFound/style.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA,EAAA,mBAAA;EACA,oCAAA,EAAA,gCAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,UAAA;EACA,UAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,WAAA;AACJ;;AACA;EACI,kBAAA;EACA,UAAA;EACA,iBAAA;EACA,iBAAA;EACA,iBAAA;EACA,WAAA;EACA,eAAA;EACA,gCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,cAAA;EACA,yBAAA;EACA,sBAAA;EACA,sBAAA;AAEJ;AADI;EACI,WAAA;EACA,yBAAA;EACA,yBAAA;AAGR","sourcesContent":[".blur_block{\n    background-color: rgb(0,0,0); /* Fallback color */\n    background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */\n    color: white;\n    font-weight: bold;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 2;\n    width: 30%;\n    padding: 20px;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    border-radius: 5px;\n  \n}\n.not_found_container{\n    position: relative;\n    background-color: #E4C0AB;\n    height: 100vh;\n    width: 100%;\n}\n.go_to_home {\n    text-align: center;\n    font: 16px;\n    font-weight: bold;\n    letter-spacing: 0;\n    padding: 0px 20px;\n    margin: 5px;\n    cursor: pointer;\n    transition: all ease-in-out 0.3s;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-transform: uppercase;\n    min-height: 40px;\n    line-height: 1;\n    border-radius: 5px;\n    color: #aa8a75;\n    border: 1px solid #aa8a75;\n    background-color: #fff;\n    text-decoration: unset;\n    &:hover {\n        color: #fff;\n        border: 1px solid #aa8a75;\n        background-color: #aa8a75;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

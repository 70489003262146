// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Poppins/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Poppins/Poppins-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Poppins/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/Playfair/PlayfairDisplay-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Poppins;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-weight: normal;
}
@font-face {
  font-family: Poppins-SemiBold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-weight: 700;
}
@font-face {
  font-family: Poppins-Bold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  font-weight: 700;
}
@font-face {
  font-family: PlayFair-Regular;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}`, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,4CAAA;EACA,mBAAA;AACJ;AAEA;EACI,6BAAA;EACA,4CAAA;EACA,gBAAA;AAAJ;AAGA;EACI,yBAAA;EACA,4CAAA;EACA,gBAAA;AADJ;AAGA;EACI,6BAAA;EACA,4CAAA;AADJ","sourcesContent":["@font-face {\n    font-family: Poppins;\n    src: url(\"./assets/fonts/Poppins/Poppins-Regular.ttf\");\n    font-weight: normal;\n}\n\n@font-face {\n    font-family: Poppins-SemiBold;\n    src: url(\"./assets/fonts/Poppins/Poppins-SemiBold.ttf\");\n    font-weight: 700;\n}\n\n@font-face {\n    font-family: Poppins-Bold;\n    src: url(\"./assets/fonts/Poppins/Poppins-Bold.ttf\");\n    font-weight: 700;\n}\n@font-face {\n    font-family: PlayFair-Regular;\n    src: url(\"./assets/fonts/Playfair/PlayfairDisplay-Regular.ttf\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

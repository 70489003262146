// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headingUI {
  margin: 0;
  font-family: Poppins-SemiBold;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/HeadingUI/style.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,6BAAA;AACJ","sourcesContent":[".headingUI {\n    margin: 0;\n    font-family: Poppins-SemiBold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

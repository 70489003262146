import { Link } from "react-router-dom";
import MenuItem from "./MenuItem";
import LoginSignBtn from "./NavRight";
import "./style.scss";
// import { slide as Menu } from "react-burger-menu";
import { useEffect, useState } from "react";
import HamburgerMenu from "./SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShop } from "@fortawesome/free-solid-svg-icons";

export const Header = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState<boolean>(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  const data = localStorage.getItem("basket")
    ? JSON.parse(localStorage.getItem("basket") || "[]")
    : [];
  return (
    <header className="header">
      <section className="header-wrapper">
        <HamburgerMenu />
        <MenuItem />
        <div className="logo">Modamia</div>
        <div className="user_cart">
          <Link to="/shopcart">
            <FontAwesomeIcon icon={faShop} />
            <span>{data?.length}</span>
          </Link>
        </div>

        {/* <LoginSignBtn /> */}
      </section>
    </header>
  );
};

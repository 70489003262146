// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-page-loader {
  display: flex;
  background-color: #fff;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999999;
}
.full-page-loader > img {
  animation: 1.8s infinite heartbeat;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/FullPageLoader/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,OAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;AACJ;AACI;EACI,kCAAA;AACR;AAEI;EACI;IACI,mBAAA;EAAV;EAGM;IACI,sBAAA;EADV;EAIM;IACI,mBAAA;EAFV;EAKM;IACI,sBAAA;EAHV;EAMM;IACI,mBAAA;EAJV;AACF","sourcesContent":[".full-page-loader {\n    display: flex;\n    background-color: #fff;\n    align-items: center;\n    flex-direction: column;\n    justify-content: center;\n    position: absolute;\n    top: 0;\n    right: 0;\n    left: 0;\n    bottom: 0;\n    width: 100%;\n    height: 100vh;\n    z-index: 99999999;\n\n    >img {\n        animation: 1.8s infinite heartbeat;\n    }\n\n    @keyframes heartbeat {\n        0% {\n            transform: scale(1);\n        }\n\n        25% {\n            transform: scale(1.05);\n        }\n\n        50% {\n            transform: scale(1);\n        }\n\n        75% {\n            transform: scale(1.05);\n        }\n\n        100% {\n            transform: scale(1);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect, useRef } from "react";
import {
  faMagnifyingGlass,
  faShop,
  faSignOutAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { isLogged, setIsLogged, setUserData } from "redux/reducers/userReducer";
import { userSignout } from "services/userService";
import { eraseCookie } from "shared/helpers/helpers";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const isUserLogged = useSelector(isLogged);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signout = async () => {
    try {
      const data = await userSignout();
      if (data) {
        eraseCookie("accessToken");
        dispatch(setIsLogged(false));
        navigate("/signin");
        dispatch(setUserData({}));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="hider">
      <div ref={menuRef}>
        <div className="hamburger" onClick={toggleMenu}>
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </div>
        <nav className={`nav-links ${isOpen ? "open" : ""}`}>
          <Link to="/customization" className="custom-nav">
            Shop
          </Link>
          {!isUserLogged ? (
            <>
              {" "}
              <Link to="/signin" className="custom-nav">
                Login
              </Link>
              <Link to="/signup" className="custom-nav">
                Join
              </Link>
            </>
          ) : (
            <div className="user_logged">
              <Link to="/profile" className="custom-nav">
                <FontAwesomeIcon icon={faUser} />
                <span>My Account</span>
              </Link>

              <button onClick={signout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Sign out</span>
              </button>
            </div>
          )}
        </nav>
      </div>
    </div>
  );
};

export default HamburgerMenu;
